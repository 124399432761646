import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ContactForm } from "@/components/contact-form";
import { Timeline } from "@/components/timeline";
import { Arrow } from "@/components/arrow";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import { Layout } from "@/components/layout";

const Digital360 = () => {
  return (
    <>
      <GatsbySeo
        title="Digital Transformation Services in India | Malaysia | USA"
        description="With modern customers becoming more tech-savvy and demanding, companies need to modernize their business models using digital technologies"
        canonical="https://www.tectratechnologies.com/digital-transformation/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/digital-transformation/",
          title: "Digital Transformation Services in India | Malaysia | USA",
          description:
            "With modern customers becoming more tech-savvy and demanding, companies need to modernize their business models using digital technologies",
          site_name: "Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
            {
              "@type": "WebPage",
              "@id":
                "https://www.tectratechnologies.com/digital-transformation/",
              url: "https://www.tectratechnologies.com/digital-transformation/",
              name: "Digital Transformation Services in India | Malaysia | USA",
              isPartOf: {
                "@id": "https://www.tectratechnologies.com/#website",
              },
              datePublished: "2020-06-23T19:06:32+00:00",
              dateModified: "2021-07-14T08:26:13+00:00",
              description:
                "With modern customers becoming more tech-savvy and demanding, companies need to modernize their business models using digital technologies",
              breadcrumb: {
                "@id":
                  "https://www.tectratechnologies.com/digital-transformation/#breadcrumb",
              },
              inLanguage: "en-US",
              potentialAction: [
                {
                  "@type": "ReadAction",
                  target: [
                    "https://www.tectratechnologies.com/digital-transformation/",
                  ],
                },
              ],
            },
            {
              "@type": "BreadcrumbList",
              "@id":
                "https://www.tectratechnologies.com/digital-transformation/#breadcrumb",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: "https://www.tectratechnologies.com/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Digital Transformation",
                },
              ],
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white h-screen flex items-center justify-center">
          <div className="w-full max-w-7xl mx-auto px-5 lg:px-8">
            <div className="text-left xl:w-3/5">
              <div className="">
                <h1 className="text-black text-4xl font-poppins font-semibold mb-4 normal-case leading-relaxed">
                  Connecting right, &amp; communicating every minute
                </h1>
                <div className="relative flex overflow-hidden justify-start items-center text-center">
                  <Link
                    to="/contact"
                    className="flex items-center text-xl normal-case hover:text-black"
                    activeClassName="border-b-2 border-black"
                  >
                    Talk to us
                    <Arrow color="black" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-center text-2xl font-poppins font-semibold captalize">
              Why us for your digital marketing services?
            </h2>
            <div className="mt-8">
              <div className="w-full h-full">
                <StaticImage
                  src="../../images/adahty54hfghf.png"
                  alt=""
                  layout="constrained"
                  placeholder="blurred"
                  quality={95}
                  height={500}
                  className="w-full h-60 md:h-92 lg:h-auto"
                  formats={["auto", "png"]}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 mt-8 lg:mt-12">
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Integrated Digital Solution
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    At Tectra Technologies, you get everything in one plate.
                    From strategy building to SEO to SEM. We have great
                    expertise in building strong digital pictures of different
                    brands. Our approach includes trends as well as technology
                    with a fuse of premium digital products. So you get
                    everything on one plate.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Data-Driven Services
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    We understand the need for data for the success of brands.
                    Thus, we make sure that all our products and services are
                    data-driven to give you the best results. Also, we analyze
                    your brand's vision and mission to understand the kind of
                    services you require.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Custom Solutions
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    We believe in unique and custom solutions as the need of
                    every brand is different. Our team understands your target
                    market and the brand's mission to create solutions that
                    increase brand visibility.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Better Pricing
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    At Tectra, we assure you the best competitive price with a
                    customer-centric approach to lead your business to success.
                    Our experts drive the audience and growth of business with
                    high-end knowledge.
                  </p>
                </div>
                <div className="md:col-span-2 text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Transparency
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    We do not create any wall between the analytical reports and
                    you as we believe to keep everything transparent. You can
                    see our first-hand efforts and online revenue as we provide
                    in-depth reports. Therefore, you can easily analyze the
                    results with our strategies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="relative overflow-hidden bg-white mt-10 lg:mt-12">
          <div className="mx-auto max-w-7xl px-0 lg:px-8 relative">
            <div className="grid grid-cols-1 items-center justify-center lg:gap-y-2 lg:grid-cols-12">
              <div className="flex flex-wrap items-center justify-center pb-4 lg:pr-12 sm:mx-0 sm:pb-0 lg:col-span-6 order-2 lg:order-1">
                <div className="relative z-10 gap-x-4 px-5 lg:px-0 lg:block lg:gap-x-0 lg:gap-y-1">
                  <p className="text-base text-black font-poppins font-thin leading-8 mb-0">
                    Our digital marketing service is led by corporate
                    communication specialists and google certified practitioners
                    who have experience in handling different sectors and
                    understand today’s fast-changing media landscape. We offer
                    prompt and efficient, digital marketing services, including
                    website designing, organic search engine optimization (SEO),
                    content writing, search engine-friendly designing, blog
                    writing, forum, and community participation and consulting.
                  </p>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4 mb-0">
                    Our Team conducts high-level quality checks at every phase
                    of the project delivering the best quality solutions even at
                    times of being prepared to handle critics. Our ultimate goal
                    is to achieve complete customer satisfaction by guiding them
                    and taking inputs from them at every step.
                  </p>
                </div>
              </div>
              <div className="lg:col-span-6 order-1 lg:order-2 mb-2 lg:mb-2">
                <div className="relative w-auto overflow-hidden lg:mt-0 lg:w-[67.8125rem]">
                  <div className="w-full h-full">
                    <StaticImage
                      src="../../images/djikbksayf.png"
                      alt=""
                      layout="constrained"
                      placeholder="blurred"
                      quality={95}
                      height={350}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                  <div className="w-full h-full mt-4">
                    <StaticImage
                      src="../../images/nordwood-themes-yyMJNPgQ-X8-unsplash1.png"
                      alt=""
                      layout="constrained"
                      placeholder="blurred"
                      quality={95}
                      height={350}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
              Process
            </h2>
            <div className="mt-6 ml-2 lg:ml-0">
              <Timeline
                items={[
                  "Marketing Objectives to Indentitfy KPIs",
                  "Understand Customers and the Market",
                  "Develop Strategic Plan",
                  "Implement Plan",
                  "Evaluate",
                ]}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Digital360;
